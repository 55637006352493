import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import Header from "../components/header"
import Footer from "../components/footer"
import Carousel from "../components/carouselKran"
import CarouselDetail from "../components/carouselKranDetail"
import ContactBox from "../components/boxContact"
import "../styles/kranverleih.css"

import imgKranDatenLast from "../assets/kran/kran_lastendiagramm.png"
import imgKranDatenBuehne from "../assets/kran/kran_buehnenbetrieb.png"
import imgKranDaten02 from "../assets/kran/kranDaten02.jpg"
import imgKranDaten03 from "../assets/kran/kranDaten03.jpg"

export default () => (
  <div>
    <Header />
    <Carousel />
    <Container fluid>
      <Row>
        <Col lg={12} xl={true} className="contentbox contact white">
          <Row>
            <Col className="text-center">
              <h1>Unser Kranverleih</h1>
              <hr />
              <div><em>Stand: 01.06.24</em></div>
              <p />
              <div className="kranverleih-table">
                Ein leistungsstarker Kran gehört zu unserem Equipment. Und den
                können Sie leihen. Fahrer und Kranführer inklusive, versteht
                sich. Hier sehen Sie einen kurzen Überblick zu den
                Leih-Gebühren. Bei Interesse melden Sie sich bitte telefonisch
                in unserem Büro.
              </div>
            </Col>
          </Row>
          <p />

          <Row>
            <Col lg={12} xl={true}>
              <table className="kranverleih-table">
                <thead>
                  <tr>
                    <th>
                      <h6>Leistungen</h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul class="kranverleih-list">
                        <li>Wartungsarbeiten an Dächern und Fassaden</li>
                        <li>Baumfällungen</li>
                        <li>Baumbeschnitt</li>
                        <li>
                          Heben von:
                          <li>Glasscheiben &amp; Abschlüsse</li>
                          <li>Baustoffen &amp; Materialien</li>
                          <li>Stahlkonstruktionen</li>
                          <li>Abbruchmaterial</li>
                          <li>Maschinen</li>
                          <li>Hallenbaumaterialien</li>
                          <li>Zimmereimaterialien</li>
                        </li>
                        <li>vieles mehr...</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            
            <Col lg={12} xl={true}>
              <table className="kranverleih-table">
                <thead>
                  <tr>
                    <th colspan="2">
                      <h6>Leihgebühren</h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Pro Stunde:</td>
                    <td className="kranverleih-cost">110,00 EUR</td>
                  </tr>
                  <tr>
                    <td>
                      Anfahrt im
                      <br />
                      im Umkreis von 25 km:
                    </td>
                    <td className="kranverleih-cost">55,00 EUR</td>
                  </tr>
                  <tr>
                    <td>
                      plus einmalige
                      <br />
                      Hakenlastversicherung:
                    </td>
                    <td className="kranverleih-cost">35,00 EUR</td>
                  </tr>
                  <tr>
                    <td>
                      Gemein- und 
                      <br />
                      Energiekostenzulage:
                      <br />
                      (pro Stunde)
                    </td>
                    <td className="kranverleih-cost">10,00 EUR</td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      Individuelle Angebote auf Anfrage
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      Straßensperrungen, längere Anfahrten etc. werden individuell nach Aufwand berechnet.
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <p/>
          <Row>
            <Col lg={12} xl={true}>
              <table className="kranverleih-table">
                <thead>
                  <tr>
                    <th>
                      <h6>Zubehör im Preis enthalten</h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul class="kranverleih-list">
                        <li>Schuttmulde</li>
                        <li>Ziegelzange</li>
                        <li>Ziegelblöcke</li>
                        <li>Ketten- und Hebebänder</li>
                        <li>Sperrgutnetz</li>
                        <li>Arbeitskorb (2 Personen)</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col lg={12} xl={true}>
              <table className="kranverleih-table">
                <thead>
                  <tr>
                    <th colspan="2">
                      <h6>Zubehör, welches zusätzlich berechnet wird</h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Hubarbeitsbühne
                      <br />
                      (2 Personen):
                    </td>
                    <td className="kranverleih-cost">50,00 EUR</td>
                  </tr>
                  <tr>
                    <td>
                      Palettengabel
                      <br />
                      (pro Einsatz):
                    </td>
                    <td className="kranverleih-cost">30,00 EUR</td>
                  </tr>
                  <tr>
                    <td>
                      Kopfstück für Glasmontage
                      <br />
                      (pro Einsatz):
                    </td>
                    <td className="kranverleih-cost">50,00 EUR</td>
                  </tr>
                  <tr>
                    <td>
                      Solarmodulhalter
                      <br />
                      (pro Einsatz)
                    </td>
                    <td className="kranverleih-cost">30,00 EUR</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <p />
          <Row>
            <Col lg={0} xl={true}>
            </Col>
            <Col lg={12} xl={true}>
              <table className="kranverleih-table">
                <thead>
                  <tr>
                    <th>
                      <h6>Baustellenbesichtigung</h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Einsatzplanung und Baustellenorganisation werden nur bei Auftragsvergabe nach Aufwand berechnet.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Richtpreise erhalten Sie auf Anfrage
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <p />
          <Row>
            <Col lg={0} xl={true}>
            </Col>
            <Col lg={12} xl={true}>
              <table className="kranverleih-table">
                <tbody>
                  <tr>
                    <td>
                      <em>(alle Preise ohne Mehrwertsteuer)</em>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Col>
        <Col lg={12} xl={6} className="contentbox contact gray">
          <div className="d-flex align-items-center">
            <CarouselDetail />
          </div>
        </Col>
      </Row>
    </Container>
    <ContactBox />
    <div className="arrow-up white" />
    <div className="contentbox white text-center">
      <h2>Leistungsdaten unseres Krans</h2>
      <hr />
    </div>
      <Container fluid>
        <Row>
          <Col
            lg={12}
            xl={true}
            className="contentbox contact gray fit text-center"
          >
            <Image src={imgKranDatenLast} fluid />
          </Col>
          <Col
            lg={12}
            xl={true}
            className="contentbox contact white fit text-center"
          >
            <Image src={imgKranDatenBuehne} fluid />
          </Col>
        </Row>
      </Container>
    <div className="arrow-up gray" />
    <div className="contentbox black foot fit" />
    <Footer />
  </div>
)
